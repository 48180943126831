.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.image-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 3%;
  justify-content: space-between;
  align-items: center;
}

.image1 {
  width: 100%;
}
.image2 {
  width: 100%;
}

.me {
  margin-bottom: 3rem;
  width: 15rem;
}

.txt-header {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  margin-bottom: 2%;
}

.txt-description {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  line-height: 38px;
  margin-bottom: 5%;
}

.project-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  margin-bottom: 3%;
  flex-wrap: wrap;
}

.image-project {
  width: 36rem;
  margin-right: 1rem;
}

.project-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 34rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-right: 1rem;
}

.txt-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.project-header {
  font-size: 16px;
  color: #7d7d7d;
  margin-top: 20px;
  margin-left: 15px;
}

.project-paragraph {
  font-size: 24px;
  font-weight: 600;
  margin-top: 40px;
  margin-left: 15px;
}

.project-description {
  font-size: 20px;
  width: 70%;
  margin-top: 10px;
  margin-left: 15px;
}

.project-button {
  width: 40%;
  height: 50px;
  border-radius: 64px;
  background-size: cover;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  transition: background-color 0.25s !important;
  text-decoration: none;
  color: black;
}

.cv {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 64px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 1.5%;
}

.cv:hover {
  background-color: #ebebeb;
  color: #000000;
}

.cv:link {
  text-decoration: none;
  color: #000000;
}

.cv:visited {
  text-decoration: none;
  color: #000000;
}

.cv:active {
  text-decoration: none;
  color: #000000;
}
