.img {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
  filter: drop-shadow(0px 1px 2px rgba(41, 41, 41, 0.3));

  :global(.carousel .control-dots .dot) {
    background-color: #ebebeb;
  }
}
