.cases-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64%;
  margin-bottom: 4%;
}

.case {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-bottom: 20px;
  align-items: center;
}

.case-header {
  font-weight: 700;
  width: 10%;
  margin-right: 1rem;
  margin-bottom: 20px;
}

.case-description {
  margin-bottom: 20px;
  width: 80%;
}
