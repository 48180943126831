.txt-header-cv {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    width: 100%;
    margin-bottom: 0.6%;
}
  
.txt-description-cv {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    color: #7d7d7d;
    letter-spacing: 2px;
    text-align: center;
}
