.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3%;
}

.case-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.txt-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 35px;
  align-items: center;
  margin-bottom: 3%;
}

.title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
}

.subTitle {
  font-size: 16px;
  color: #7d7d7d;
  text-align: center;
}

.description {
  font-size: 16px;
  text-align: center;
  width: 70%;
}

.title-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3%;
}

.title-img-wrapper-3 {
  /* justify-content: space-between; */
}

.img-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  width: 100%;
  margin-bottom: 3%;
}
