html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
article {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 39.6px;
  font-weight: 500;
}

h2 {
  font-size: 32px;
  font-weight: 400;
}

h3 {
  font-size: 25px;
  font-weight: 400;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

h6 {
  font-size: 12px;
  font-weight: 400;
}

.wrap {
  margin-top: 4rem;
  margin-left: 15rem;
  margin-right: 15rem;
}

.wrap-mobile {
  width: 100%;
  margin-top: 30vh;
}

.footer {
  width: 100vw;
  background: #ebebeb;
  margin-top: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.app {
  display: none;

  @media (min-width: 1366px) {
    display: block;
  }
}
