.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .backbutton {
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    border-radius: 64px;
    background-size: cover;
    display: flex;
    justify-content: right;
    text-decoration: none;
    cursor: pointer;
    margin-top: 1.5%;
  }
  
  
  