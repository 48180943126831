.rwd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  text-align: center;
  margin-bottom: 0.5em;

  @media (min-width: 1366px) {
    display: none;
  }
}

.rwd-icon {
  width: 15rem;
  margin-bottom: 3rem;
}

.rwd-text {
  width: 60%;
}
