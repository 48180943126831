.button {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 64px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 1.5%;
  align-self: flex-start;
}

.arrow {
  margin-right: 0.5rem;
}

.button:hover {
  background-color: #ebebeb;
  color: #000000;
}

.button:link {
  text-decoration: none;
  color: #000000;
}

.button:visited {
  text-decoration: none;
  color: #000000;
}

.button:active {
  text-decoration: none;
  color: #000000;
}
